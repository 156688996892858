import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from 'antd'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { OutboundLink } from 'gatsby-plugin-gtag'

import 'typeface-muli'
import 'typeface-alegreya'
import 'typeface-source-sans-pro'

import Logo from '@static/no-bg.png'
import AppleTouchIcon from '@static/favicon/apple-touch-icon.png'
import Favicon32 from '@static/favicon/favicon-32x32.png'
import Favicon16 from '@static/favicon/favicon-16x16.png'
// import FaviconManifest from '@static/favicon/site.webmanifest'

const MainLayout = ({ children }) => {
  const [collapsed, toggle] = useState(true)
  return (
    <div className='main-layout flex flex-row font-body'>
      <Helmet>
        <title>Severian</title>
        <description>
          Hi! Here&apos;s where I tinker with tech, philosophy and cognition.
        </description>
        <link rel='apple-touch-icon' sizes='180x180' href={AppleTouchIcon} />
        <link rel='icon' type='image/png' sizes='32x32' href={Favicon32} />
        <link rel='icon' type='image/png' sizes='16x16' href={Favicon16} />
        {/* <link rel='manifest' href={FaviconManifest} /> */}
      </Helmet>
      <div className='max-w-screen-lg w-full flex flex-col md:flex-row m-auto'>
        <div className='sticky top-0 h-auto md:h-screen sider w-full md:w-3/12'>
          <div className='relative flex flex-row bg-white py-2 px-6 lg:px-10 justify-between items-center shadow-md md:shadow-none z-40'>
            <Link to='/'>
              <img src={Logo} alt='logo' className='w-12 md:w-16' />
            </Link>
            <Button
              size='large'
              className='block md:hidden'
              onClick={() => toggle(!collapsed)}
            >
              <FontAwesomeIcon icon={faBars} />
            </Button>
          </div>
          <div
            className={classNames(
              'absolute md:relative w-full bg-white md:bg-opacity-100 px-6 lg:px-10 shadow md:shadow-none py-2 md:py-0 transition ease-out duration-300 transform z-0 md:translate-y-0',
              {
                '-translate-y-full': collapsed,
                '-translate-y-0': !collapsed,
              },
            )}
          >
            <hr className='hidden md:block' />
            <div className='my-4 font-subheaders text-xl'>
              <div>
                <Link to='/about'>About</Link>
              </div>
              <div>
                <Link to='/now'>Now</Link>
              </div>
              <div>
                <Link to='/essays'>Essays</Link>
              </div>
              {/* <div>
                <OutboundLink href='https://notes.severian.dev'>
                  Notes <sup>β</sup>
                </OutboundLink>
              </div> */}
              {/* <div>
                <Link to='/invitation'>
                  Fiction <sup>∅</sup>
                </Link>
              </div> */}
              {/* <div>
                <Link to='/invitation'>Invitation</Link>
              </div> */}
              {/* <div>
                <Link to='/contact'>Contact</Link>
              </div> */}
            </div>
            <hr className='hidden md:block' />
            <div className='my-4 hidden md:block font-body text-xs text-gray-300'>
              <div>The only way out is through. © 2020</div>
            </div>
          </div>
        </div>
        <div className='content-area w-full md:p-10 p-6'>{children}</div>
      </div>
    </div>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default memo(MainLayout)
